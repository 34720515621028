import { ChangeEvent, KeyboardEventHandler, useState } from 'react';

import { LoginMeta } from './login.types';
import { setSentryAndAnalyticsAfterLogin } from './login.utils';

import { ErrorCodes } from '@/constants/errors.constants';
import {
  EMAIL_OR_PASSWORD_ERROR,
  GENERAL_ERROR_TRY_LATER
} from '@/constants/user-messages.constants';
import { AnalyticEventAction } from '@/services/analytics/analytics.types';
import { trackActionButtonAnalyticsEvent } from '@/services/analytics/analytics-events/action-button-event';
import { trackErrorAnalyticsEvent } from '@/services/analytics/analytics-events/error-event';
import { trackLoginAnalyticsEvent } from '@/services/analytics/analytics-events/login-event';
// import { AnalyticEventAction } from 'analytics';
// import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { getIsAutomationClient } from '@/utils/EnvUtils';
import { redirectAfterAuthentication } from '@/utils/redirect.utils';
import { standardLogin } from '@/utils/requests.utils';

// TODO: verify it works for QA wolf

const emptyLoginMeta: LoginMeta = {
  userId: null,
  userPhone: null,
  should2FA: false
};

const useStandardLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [metadata, setMetadata] = useState<LoginMeta>(emptyLoginMeta);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoginStandardLoading, setIsLoginStandardLoading] = useState(false);

  const login = async () => {
    const isAutomationClient = getIsAutomationClient();

    if (!email || !password) {
      setErrorMessage(EMAIL_OR_PASSWORD_ERROR);

      if (!isAutomationClient) {
        trackActionButtonAnalyticsEvent({
          action: AnalyticEventAction.Login,
          value: 'missing fields'
        });
      }
      return;
    }

    try {
      setIsLoginStandardLoading(true);

      if (!isAutomationClient) {
        trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.Login });
      }

      const { should2FA, userPhone, clinicianId, ...analyticsMeta } = await standardLogin(
        email,
        password
      );

      if (!should2FA) {
        setSentryAndAnalyticsAfterLogin(clinicianId, analyticsMeta);
        trackLoginAnalyticsEvent({ action: AnalyticEventAction.Password, with2FA: false });
        redirectAfterAuthentication(analyticsMeta.releaseGroup);
      } else {
        setIsLoginStandardLoading(false);
      }

      setMetadata({ userId: analyticsMeta.userId, userPhone, should2FA });
    } catch (error: any) {
      if (error.statusCode === ErrorCodes.Unauthorized) {
        setErrorMessage(EMAIL_OR_PASSWORD_ERROR);
        trackErrorAnalyticsEvent({ value: 'Wrong username or password' });
      } else {
        setErrorMessage(GENERAL_ERROR_TRY_LATER);
      }
      setIsLoginStandardLoading(false);
      setMetadata(emptyLoginMeta);
    }
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const loginWithEnter: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      login();
    }
  };

  const clearErrorMessage = () => setErrorMessage('');

  return {
    standardLogin: login,
    standardLoginWithEnter: loginWithEnter,
    email,
    password,
    onEmailChange,
    onPasswordChange,
    standardLoginMetadata: metadata,
    standardLoginErrorMessage: errorMessage,
    clearStandardLoginErrorMessage: clearErrorMessage,
    isLoginStandardLoading
  };
};

export default useStandardLogin;
