import * as Sentry from '@sentry/nextjs';

import { AnalyticsMeta } from './login.types';

import analyticsService from '@/services/analytics/analytics.service';

export function setSentryAndAnalyticsAfterLogin(clinicianId: number, analyticsMeta: AnalyticsMeta) {
  Sentry.getCurrentScope()?.setUser({ id: String(clinicianId) });
  verifyAnalyticsMeta(analyticsMeta);
  analyticsService.analyticsSetup(analyticsMeta);
}

function verifyAnalyticsMeta(analyticsMeta: AnalyticsMeta) {
  if (
    !analyticsMeta.userId ||
    !analyticsMeta.institutionId ||
    !analyticsMeta.institutionName ||
    !analyticsMeta.releaseGroup
  ) {
    const error = new Error(`Missing analytics meta data: ${JSON.stringify(analyticsMeta)}`);

    Sentry.captureException(error);
  }
}
