import { AxiosRequestConfig } from 'axios';

export enum HTTP_METHODS {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT'
}

const CRUD_METHODS = [HTTP_METHODS.GET, HTTP_METHODS.POST, HTTP_METHODS.PUT];

export function isCrud(method: string) {
  method = method.toUpperCase();
  return CRUD_METHODS.includes(method as HTTP_METHODS);
}

type AxiosRequestParams = Pick<AxiosRequestConfig, 'url' | 'method'>;

export interface RequestParams extends Omit<AxiosRequestParams, 'method'> {
  url: string; // make url required
}

export interface RequestParamsWithData<Data = any> extends Omit<RequestParams, 'method'> {
  data?: Data;
}
// allow all possible params
export type AllRequestParams = RequestParams & RequestParamsWithData & AxiosRequestParams;
