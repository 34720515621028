import { PaletteOptions } from '@mui/material';

// Canopy colors - https://www.figma.com/file/vaQnuineafX4UuLgnnGDam/New-General-Design-System?node-id=0%3A1
// Color names inspired by material colors https://material.io/design/color/the-color-system.html#tools-for-picking-colors
type HEX = `#${string}`;

type NaturalKeys =
  | 'white'
  | 'black'
  | 'inactiveBackground'
  | 'contrast'
  | 'contrastDark'
  | 'hover'
  | 'pressed'
  | 'shadow'
  | 'border';
type Natural = Record<NaturalKeys, HEX>;

const naturalColors: Natural = {
  white: '#fff',
  black: '#000',
  inactiveBackground: '#F3F3F3',
  contrast: '#FBFBF9',
  contrastDark: '#F6F6F1',
  hover: '#EFF1F2',
  pressed: '#BFBFBF',
  shadow: '#E0E2E4',
  border: '#E3E3E3'
};
declare module '@mui/material/styles' {
  // extends MUI palette with our custom colors
  interface Palette {
    natural: Natural;
  }
  // extends MUI palette options with our custom colors
  interface PaletteOptions {
    natural: Natural;
  }
}

export const palette: PaletteOptions = {
  primary: {
    main: '#007C66',
    contrastText: '#3366cc'
  },
  error: {
    main: '#F75F32',
    dark: '#E30000',
    light: '#FFE8E3',
    contrastText: '#781E0E'
  },
  text: {
    primary: '#1E233E'
  },
  grey: {
    50: '#e3e3e3',
    100: '#f3f3f3',
    200: '#676A7B'
  },
  natural: naturalColors
};
