'use client';
import { FC } from 'react';

import { Box } from '@mui/material';

import { CanopyLogo, FormWrapper, Input, LoginErrorMessage } from '../login.ui';

import { useLoginContext } from '@/app/login.context';
import { FilledButton, TextButton } from '@/components/UIkit/atoms/Button';
import { AnalyticEventAction } from '@/services/analytics/analytics.types';
import { trackActionButtonAnalyticsEvent } from '@/services/analytics/analytics-events/action-button-event';
import { getIsAutomationClient } from '@/utils/EnvUtils';

export const LoginForm: FC = () => {
  const {
    loginWithSso,
    ssoLoginErrorMessage,
    onPasswordChange,
    onEmailChange,
    standardLogin,
    standardLoginErrorMessage,
    standardLoginWithEnter,
    clearErrorMessage
  } = useLoginContext();
  const errorMessage = ssoLoginErrorMessage || standardLoginErrorMessage;

  const hasError = Boolean(errorMessage);

  const onLoginWithSsoClick = () => {
    const isAutomationClient = getIsAutomationClient();

    if (!isAutomationClient) {
      trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.LoginWithSSO });
    }

    clearErrorMessage();
    loginWithSso();
  };

  const onStandardLoginClick = async () => {
    clearErrorMessage();
    await standardLogin();
  };

  return (
    <FormWrapper>
      <Box mb={12} display="flex">
        <CanopyLogo />
      </Box>
      <LoginErrorMessage hasError={hasError} errorMessage={errorMessage} />
      <Input
        type="email"
        data-test-hook="email"
        onChange={onEmailChange}
        placeholder="Email"
        autoComplete="email"
        onKeyDown={standardLoginWithEnter}
        isError={hasError}
      />

      <Input
        type="password"
        data-test-hook="password"
        onChange={onPasswordChange}
        autoComplete="current-password"
        placeholder="Password"
        onKeyDown={standardLoginWithEnter}
        isError={hasError}
      />

      <FilledButton onClick={onStandardLoginClick} fullWidth mt={40} mb={20} mx={0}>
        Log In
      </FilledButton>
      <Box alignSelf="center">
        <TextButton onClick={onLoginWithSsoClick} size="large">
          Log In with SSO
        </TextButton>
      </Box>
    </FormWrapper>
  );
};
