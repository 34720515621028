import AnalyticsService, { AnalyticsEventProperties } from '@/services/analytics/analytics.service';
import { AnalyticEventName } from '@/services/analytics/analytics.types';

interface Properties extends AnalyticsEventProperties {
  value: string;
}

export const trackErrorAnalyticsEvent = (properties: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.Error,
    properties
  });
};
