import { skipRedirectOnDev } from './EnvUtils';

import { ReleaseGroup } from '@/components/login/login.types';
import { publicUrlsService } from '@/services/public-urls/public-urls.service';

export function redirectAfterAuthentication(releaseGroup: ReleaseGroup) {
  const urlKey = releaseGroup === 'canary' ? 'portalCanary' : 'portal';

  if (skipRedirectOnDev) {
    return console.log(`dev-mode - skipping redirect to: ${publicUrlsService.publicUrls[urlKey]}`);
  }
  window.location.assign(publicUrlsService.getUrlWithCurrentProtocol(urlKey));
}
