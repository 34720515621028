'use client';
import {
  ChangeEvent,
  createContext,
  FC,
  KeyboardEventHandler,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import * as Sentry from '@sentry/nextjs';

import { LoginMeta } from '@/components/login/login.types';
import useLoginWithSSO from '@/components/login/use-login-with-sso';
import useStandardLogin from '@/components/login/use-standard-login';
import { ErrorCodes } from '@/constants/errors.constants';
import { redirectAfterAuthentication } from '@/utils/redirect.utils';
import { checkSession } from '@/utils/requests.utils';

type LoginContextType = {
  ssoLoginErrorMessage: string;
  loginWithSso: () => void;
  isAuthenticatedWithSso: boolean;
  isLoginStandardLoading: boolean;
  isLoginWithSsoLoading: boolean;
  email: string;
  password: string;
  onPasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  standardLogin: () => Promise<void>;
  standardLoginMetadata: LoginMeta;
  standardLoginErrorMessage: string;
  standardLoginWithEnter: KeyboardEventHandler<HTMLInputElement>;
  clearErrorMessage: () => void;
  isAuthenticatingSession: boolean;
};

export const LoginContext = createContext<LoginContextType>({} as LoginContextType);

export function useLoginContext() {
  return useContext(LoginContext);
}

export const LoginContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticatingSession, setIsAuthenticatedSession] = useState(true);
  const {
    ssoLoginErrorMessage,
    loginWithSso,
    isAuthenticatedWithSso,
    isLoginWithSsoLoading,
    clearSsoErrorMessage
  } = useLoginWithSSO();

  const {
    email,
    password,
    onPasswordChange,
    onEmailChange,
    standardLogin,
    isLoginStandardLoading,
    standardLoginMetadata,
    standardLoginErrorMessage,
    standardLoginWithEnter,
    clearStandardLoginErrorMessage
  } = useStandardLogin();

  useEffect(function init() {
    const initLogin = async () => {
      try {
        const { releaseGroup } = await checkSession();

        if (releaseGroup) {
          redirectAfterAuthentication(releaseGroup);
          return;
        }

        setIsAuthenticatedSession(false);
      } catch (error: any) {
        // if the user is not authenticated, we don't want to capture the error
        if (error.statusCode !== ErrorCodes.Forbidden) {
          Sentry.captureException(error);
        }

        setIsAuthenticatedSession(false);
      }
    };

    initLogin();
  }, []);

  const clearErrorMessage = () => {
    clearSsoErrorMessage();
    clearStandardLoginErrorMessage();
  };

  return (
    <LoginContext.Provider
      value={{
        ssoLoginErrorMessage,
        loginWithSso,
        isAuthenticatedWithSso,
        isLoginStandardLoading,
        isLoginWithSsoLoading,
        email,
        password,
        onPasswordChange,
        onEmailChange,
        standardLogin,
        standardLoginMetadata,
        standardLoginErrorMessage,
        standardLoginWithEnter,
        clearErrorMessage,
        isAuthenticatingSession
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
