import { ChangeEvent, FC, KeyboardEventHandler, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { FormWrapper, Input, LoginErrorMessage } from '../login.ui';
import { setSentryAndAnalyticsAfterLogin } from '../login.utils';

import { FilledButton, TextButton } from '@/components/UIkit/atoms/Button';
import { ErrorCodes } from '@/constants/errors.constants';
import {
  GENERAL_ERROR_CONTACT_SUPPORT,
  INCORRECT_VERIFICATION_CODE_ERROR
} from '@/constants/user-messages.constants';
import { AnalyticEventAction } from '@/services/analytics/analytics.types';
import { trackLoginAnalyticsEvent } from '@/services/analytics/analytics-events/login-event';
import { redirectAfterAuthentication } from '@/utils/redirect.utils';
import { callWith2FACode, resend2FACode, verify2FACode } from '@/utils/requests.utils';

interface IProps {
  userPhone: string;
  email: string;
  password: string;
  setIsAuthenticatingBySms: (isAuthenticating: boolean) => void;
}

enum MessageType {
  Info = 'info',
  Error = 'error'
}

export const SmsVerification: FC<IProps> = ({
  userPhone,
  email,
  password,
  setIsAuthenticatingBySms
}) => {
  const [code, setCode] = useState<string | null>(null);
  const [waitingResponse, setWaitingResponse] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<MessageType>(MessageType.Info);

  const handleVerifyCode = async () => {
    setIsAuthenticatingBySms(true);
    setMessage(null);
    try {
      const { clinicianId, ...analyticsMeta } = await verify2FACode(
        code as string,
        email,
        password
      );
      setSentryAndAnalyticsAfterLogin(clinicianId, analyticsMeta);
      trackLoginAnalyticsEvent({ action: AnalyticEventAction.Password, with2FA: true });
      redirectAfterAuthentication(analyticsMeta.releaseGroup);
    } catch (error: any) {
      setMessageType(MessageType.Error);

      if (error.statusCode === ErrorCodes.Unauthorized) {
        setMessage(INCORRECT_VERIFICATION_CODE_ERROR);
      } else {
        setMessage(GENERAL_ERROR_CONTACT_SUPPORT);
      }
      setIsAuthenticatingBySms(false);
    }
  };

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleVerifyCode();
    }
  };

  const handleResendCode = async () => {
    setWaitingResponse(true);
    setMessage(null);
    try {
      await resend2FACode(email, password);
      setMessageType(MessageType.Info);
      setMessage('Code Sent Again');
    } catch {
      setMessageType(MessageType.Error);
      setMessage(GENERAL_ERROR_CONTACT_SUPPORT);
    } finally {
      setWaitingResponse(false);
    }
  };

  const handleCallMe = async () => {
    setWaitingResponse(true);
    setMessage(null);
    try {
      await callWith2FACode(email, password);
      setMessageType(MessageType.Info);
      setMessage('You’ll receive a call with the code in just a moment.');
    } catch {
      setMessageType(MessageType.Error);
      setMessage(GENERAL_ERROR_CONTACT_SUPPORT);
    } finally {
      setWaitingResponse(false);
    }
  };

  const userPhoneNumber = parsePhoneNumberFromString(userPhone)?.formatNational();

  const hasError = Boolean(message) && messageType === MessageType.Error;

  return (
    <>
      <Typography variant="h1">SMS Verification Code</Typography>
      <Box my={12}>
        <Typography variant="form-text">
          When logging in remotely, SMS verification is required. Please enter the code sent to:{' '}
          <b>{userPhoneNumber}</b>. If this number is incorrect, please contact us for assistance.
        </Typography>
      </Box>
      <FormWrapper>
        <LoginErrorMessage hasError={hasError} errorMessage={message as string} />

        <Input
          type="text"
          id="name"
          className="code-input"
          value={code || ''}
          placeholder="Enter Code"
          onChange={onCodeChange}
          onKeyDown={handleKeyDown}
          isError={hasError}
        />

        <FilledButton onClick={handleVerifyCode} disabled={waitingResponse} fullWidth>
          Submit
        </FilledButton>
        <Box my={12}>
          <TextButton onClick={handleResendCode} size="large">
            Resend Code
          </TextButton>
          <TextButton onClick={handleCallMe} size="large">
            Call Me Instead
          </TextButton>
        </Box>
      </FormWrapper>
    </>
  );
};
