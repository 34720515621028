import AnalyticsService, { AnalyticsEventProperties } from '@/services/analytics/analytics.service';
import { AnalyticEventAction, AnalyticEventName } from '@/services/analytics/analytics.types';

type Action = AnalyticEventAction.Password | AnalyticEventAction.SSO;

interface Properties extends AnalyticsEventProperties {
  action: Action;
  with2FA?: boolean;
}

export const trackLoginAnalyticsEvent = ({ action, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.Login,
    properties: { action, ...optionalProps }
  });
};
