'use client';
import { createTheme, Theme } from '@mui/material/styles';

import { extraThemeOptions } from './extraThemeOptions';
import { spacingSizeArray } from './spacing';

import { palette } from '@/styles/theme/colors';
import { components } from '@/styles/theme/components';
import { typography } from '@/styles/theme/typography';


const theme: Theme = createTheme(
  {
    typography,
    palette,
    components,
    spacing: (value: number | string) => {
      if (value === 'auto') {
        return 'auto';
      }

      if (spacingSizeArray.includes(value as number)) {
        return value;
      }

      throw new Error(`Spacing with ${value} value is not from the spacing system`);
    }
  },
  extraThemeOptions
);

export default theme;
