'use client';
import React from 'react';
import { FC } from 'react';

import { Box,css, styled, Typography } from '@mui/material';
import Image from 'next/image';

import CanopyLogoImage from '/public/images/canopy-logo-blue.svg';
import loginMainImage from '/public/images/doctor-with-female-patient@2x.jpg';

const MAX_IMAGE_WIDTH = '932px';

export const LoginContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isBlur'
})<{ isBlur: boolean }>(({ theme, isBlur }) => {
  return css`
    filter: blur(${isBlur ? '4px' : 0});
    display: flex;
    height: 100%;
    background-color: ${theme.palette.natural.contrast};
    overflow: hidden;
  `;
});

export const LoginContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 450px;

  @media only screen and (max-width: ${MAX_IMAGE_WIDTH}) {
    width: fit-content;
  }
`;

export const LoginMainImageContainer = styled('div')`
  display: flex;
  height: 110%;
  flex: 0 0 50%;
  background-image: url(${loginMainImage.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;

  @media only screen and (max-width: ${MAX_IMAGE_WIDTH}) {
    display: none;
  }
`;

export const CanopyLogo: FC = () => (
  <Image src={CanopyLogoImage} width={261} height={71} alt="Logo" priority />
);

export const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 260px;
`;

export const Input = styled('input', {
  shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
  ({ theme, isError }) => css`
    box-sizing: border-box;
    border-radius: ${theme.borderRadius.large};
    border: solid 1px
      ${isError ? theme.palette.error.dark : theme.palette.natural.inactiveBackground};
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.natural.white};
    padding: ${theme.spacing(4)} ${theme.spacing(12)};
    margin-bottom: ${theme.spacing(20)};
    height: 45px;
    outline: none;

    &:focus {
      border: solid 1px ${isError ? theme.palette.error.dark : theme.palette.primary.main};
    }
  `
);

const ErrorMessageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'show'
})<{ show: boolean }>(
  ({ theme, show }) => css`
    display: flex;
    opacity: ${show ? '1' : '0'};
    align-items: center;
    padding: ${theme.spacing(12)};
    background-color: ${theme.palette.error.light};
    margin-bottom: ${theme.spacing(12)};
    border-radius: ${theme.borderRadius.xSmall};
    color: ${theme.palette.error.dark};
    gap: ${theme.spacing(8)};
  `
);

// this is the simplest way to render svg in nextjs (not as an <img> tag)
const XThinImage: FC = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.72871 5.96122L11.5963 2.10359C12.1099 1.59139 12.1219 0.838066 11.6361 0.358621C11.1528 -0.11842 10.4409 -0.136625 9.89565 0.407192L6.0278 4.265L2.13074 0.378053C1.7152 -0.0364327 0.902683 -0.181763 0.360938 0.358583C-0.180808 0.898929 -0.0710422 1.60959 0.42845 2.1078L4.31704 5.98629C4.31704 5.98629 0.894187 9.38557 0.408625 9.86749C-0.0769364 10.3494 -0.173914 11.0738 0.359528 11.606C0.892979 12.1382 1.68591 12.0021 2.11733 11.5718L6.01736 7.68183L10.0625 11.7165C10.4612 12.1143 11.1296 12.1084 11.6356 11.6037C12.0695 11.1709 12.127 10.3999 11.7482 10.0351L7.72871 5.96122Z"
      fill="currentColor"
    />
  </svg>
);

export const LoginErrorMessage: FC<{ hasError: boolean; errorMessage: string }> = ({
  hasError,
  errorMessage
}) => (
  <ErrorMessageContainer show={hasError}>
    <Box width={12}>
      <XThinImage />
    </Box>
    <Typography variant="helper-text">{errorMessage}</Typography>
  </ErrorMessageContainer>
);
