import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { setSentryAndAnalyticsAfterLogin } from './login.utils';

import { ErrorCodes } from '@/constants/errors.constants';
import { GENERAL_ERROR_TRY_LATER } from '@/constants/user-messages.constants';
import { AnalyticEventAction } from '@/services/analytics/analytics.types';
import { trackLoginAnalyticsEvent } from '@/services/analytics/analytics-events/login-event';
import { redirectAfterAuthentication } from '@/utils/redirect.utils';
import { checkUserRegisteredAfterSsoSucceed } from '@/utils/requests.utils';

const THREE_DAYS_IN_SECONDS = 259_200;

// TODO: THIS ENTIRE FILE TAKEN FROM DASHBOARD AND NEEDS TO BE REFACTORED - EH-6286
const useLoginWithSSO = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const {
    isAuthenticated: isAuthenticatedWithSso,
    getAccessTokenSilently,
    logout,
    loginWithPopup,
    isLoading
  } = useAuth0();

  useEffect(
    function login() {
      const getUserMetadata = async () => {
        try {
          const accessToken = await getAccessTokenSilently();

          const { clinicianId, ...analyticsMeta } = await checkUserRegisteredAfterSsoSucceed(
            accessToken
          );
          setSentryAndAnalyticsAfterLogin(clinicianId, analyticsMeta);
          trackLoginAnalyticsEvent({ action: AnalyticEventAction.SSO, with2FA: false });
          redirectAfterAuthentication(analyticsMeta.releaseGroup);
        } catch (error: any) {
          if (error.errorCode === ErrorCodes.SsoAuthenticatedButNotRegistered) {
            setErrorMessage('No user found for this email.');
            logout({
              openUrl: false,
              logoutParams: {
                returnTo: window.location.origin
              }
            });
            return;
          }
          setErrorMessage(GENERAL_ERROR_TRY_LATER);
        }
      };

      if (isAuthenticatedWithSso) {
        getUserMetadata();
      }
    },
    [getAccessTokenSilently, isAuthenticatedWithSso, logout, errorMessage]
  );

  const clearErrorMessage = () => setErrorMessage('');

  return {
    ssoLoginErrorMessage: errorMessage,
    isAuthenticatedWithSso,
    loginWithSso: Boolean(errorMessage)
      ? () =>
          loginWithPopup(
            {
              authorizationParams: {
                prompt: 'login'
              }
            },
            { timeoutInSeconds: THREE_DAYS_IN_SECONDS }
          )
      : () => loginWithPopup({}, { timeoutInSeconds: THREE_DAYS_IN_SECONDS }),
    isLoginWithSsoLoading: isLoading,
    clearSsoErrorMessage: clearErrorMessage
  };
};

export default useLoginWithSSO;
