import { FC } from 'react';

import styles from './loading.module.css';

const Loader: FC = () => {
  return (
    <div className={styles.ldsGrid}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export { Loader };
