import { FC } from 'react';

import { Backdrop } from './backdrop';

import { Loader } from '@/components/backdrop-loader/loader';

interface Props {
  isLoading: boolean;
}

const BackdropLoader: FC<Props> = ({ isLoading }) => (
  <>
    <Loader />
    <Backdrop isOpen={isLoading} />
  </>
);

export { BackdropLoader };
