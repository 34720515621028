import { CSSProperties } from 'react';

import { Palette } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { extraThemeOptions } from './extraThemeOptions';

// Canopy Typography - https://www.figma.com/file/dvGOw2FFkptFtzpBTfEsNF/Clinician-Design-System?node-id=9%3A13170
// MUI default Typography https://mui.com/material-ui/react-typography/#component
declare module '@mui/material/styles' {
  interface TypographyVariantsOptions {
    h1: CSSProperties;
    h2: CSSProperties;
    h3: CSSProperties;
    h4: CSSProperties;
    'form-text': CSSProperties;
    body1: CSSProperties;
    body2: CSSProperties;
    body3: CSSProperties;
    subtext: CSSProperties;
    'button-large': CSSProperties;
    'button-medium': CSSProperties;
    'button-medium-outline': CSSProperties;
    'button-medium-second-line': CSSProperties;
    'button-small': CSSProperties;
    'button-small-selected': CSSProperties;
    tab: CSSProperties;
    task: CSSProperties;
    badge: CSSProperties;
    'helper-text': CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    'form-text': true;
    body3: true;
    subtext: true;
    'button-large': true;
    'button-medium': true;
    'button-medium-outline': true;
    'button-medium-second-line': true;
    'button-small': true;
    'button-small-selected': true;
    tab: true;
    task: true;
    badge: true;
    'helper-text': true;
    caption: false;
    button: false;
    overline: false;
  }
}

export const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
  h1: {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.xLarge,
    lineHeight: '28px'
  },
  h2: {
    fontWeight: extraThemeOptions.fontWeights.normal,
    fontSize: extraThemeOptions.fontSizes.xLarge,
    lineHeight: '28px'
  },
  h3: {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.large,
    lineHeight: '22px'
  },
  h4: {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.medium,
    lineHeight: '20px'
  },
  'form-text': {
    fontWeight: extraThemeOptions.fontWeights.normal,
    fontSize: extraThemeOptions.fontSizes.medium,
    lineHeight: '20px'
  },
  body1: {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.normal,
    lineHeight: '16px'
  },
  body2: {
    fontWeight: extraThemeOptions.fontWeights.normal,
    fontSize: extraThemeOptions.fontSizes.normal,
    lineHeight: '16px'
  },
  body3: {
    fontWeight: extraThemeOptions.fontWeights.bold,
    fontSize: extraThemeOptions.fontSizes.normal,
    lineHeight: '16px'
  },
  subtext: {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.small,
    lineHeight: '14px'
  },
  'button-large': {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.normal,
    lineHeight: '16px'
  },
  tab: {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.normal,
    textTransform: 'uppercase',
    lineHeight: '16px'
  },
  'button-medium': {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.normal,
    lineHeight: '16px'
  },
  'button-medium-outline': {
    fontWeight: extraThemeOptions.fontWeights.normal,
    fontSize: extraThemeOptions.fontSizes.normal,
    lineHeight: '16px'
  },
  'button-medium-second-line': {
    fontWeight: extraThemeOptions.fontWeights.normal,
    fontSize: extraThemeOptions.fontSizes.xSmall,
    lineHeight: '12px'
  },
  'button-small': {
    fontWeight: extraThemeOptions.fontWeights.normal,
    fontSize: extraThemeOptions.fontSizes.small,
    lineHeight: '14px'
  },
  'button-small-selected': {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.small,
    lineHeight: '14px'
  },
  task: {
    fontWeight: extraThemeOptions.fontWeights.medium,
    fontSize: extraThemeOptions.fontSizes.small,
    lineHeight: '14px'
  },
  badge: {
    fontWeight: extraThemeOptions.fontWeights.xBold,
    fontSize: extraThemeOptions.fontSizes.normal,
    lineHeight: '16px'
  },
  'helper-text': {
    fontWeight: extraThemeOptions.fontWeights.normal,
    fontSize: extraThemeOptions.fontSizes.small,
    lineHeight: '14px',
    fontStyle: 'italic'
  }
};
