import axios, { AxiosError } from 'axios';

import {
  AllRequestParams,
  HTTP_METHODS,
  RequestParams,
  RequestParamsWithData
} from '@/services/http/http.utils';
import { publicUrlsService } from '@/services/public-urls/public-urls.service';

const defaultHeaders = {
  'Content-Type': 'application/json'
};

const axiosInstance = axios.create({
  headers: defaultHeaders,
  withCredentials: true,
  baseURL: publicUrlsService.getUrlWithCurrentProtocol('loginApi'),
});

export class HttpService {
  static get = async <Response = any>({ ...rest }: RequestParams) => {
    return await HttpService.request<Response>({
      method: HTTP_METHODS.GET,
      ...rest
    });
  };

  static post = async <Response = any, Data = any>(params: RequestParamsWithData<Data>) =>
    await HttpService.request<Response>({
      method: HTTP_METHODS.POST,
      ...params
    });

  static put = async <Response = any, Data = any>(params: RequestParamsWithData<Data>) =>
    await HttpService.request<Response>({
      method: HTTP_METHODS.PUT,
      ...params
    });

  private static request = async <Response = any>(params: AllRequestParams) => {
    try {
      const { data: responseData } = await axiosInstance.request<Response>(params);
      return responseData;
    } catch (originError) {
      // https://axios-http.com/docs/handling_errors
      // for type completion purposes only
      const axiosError = originError as AxiosError;
      // this is the only case where server responded with an error
      if (axiosError.response) {
        throw axiosError.response.data;
      }

      // for any other error
      throw axiosError;
      // No error conversion needed
    }
  };
}
