'use client';
import { FC, useState } from 'react';

import { CssBaseline } from '@mui/material';

import { BackdropLoader } from '../backdrop-loader/backdrop-loader';

import { LoginContainer, LoginContent, LoginMainImageContainer } from './login.ui';
import { LoginForm } from './login-form/login-form';
import { SmsVerification } from './sms-verification/sms-verification';

import { useLoginContext } from '@/app/login.context';
import { Loader } from '@/components/backdrop-loader/loader';

export const Login: FC = () => {
  const {
    email,
    password,
    standardLoginMetadata,
    ssoLoginErrorMessage,
    isLoginWithSsoLoading,
    isLoginStandardLoading,
    isAuthenticatedWithSso,
    isAuthenticatingSession
  } = useLoginContext();

  const showSmsVerification = standardLoginMetadata.userId && standardLoginMetadata.should2FA;
  const [isAuthenticatingBySms, setIsAuthenticatingBySms] = useState<boolean>(false);

  const showBackdropLoader =
    isLoginStandardLoading ||
    isLoginWithSsoLoading ||
    isAuthenticatingBySms ||
    (isAuthenticatedWithSso && !ssoLoginErrorMessage);

  if (isAuthenticatingSession) {
    return <Loader />;
  }

  return (
    <>
      <CssBaseline />

      {showBackdropLoader && <BackdropLoader isLoading={showBackdropLoader} />}

      <LoginContainer isBlur={showBackdropLoader}>
        <LoginContent>
          {showSmsVerification ? (
            <SmsVerification
              setIsAuthenticatingBySms={setIsAuthenticatingBySms}
              userPhone={standardLoginMetadata.userPhone!}
              email={email}
              password={password}
            />
          ) : (
            <LoginForm />
          )}
        </LoginContent>
        <LoginMainImageContainer />
      </LoginContainer>
    </>
  );
};
