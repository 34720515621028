import AnalyticsService, { AnalyticsEventProperties } from '@/services/analytics/analytics.service';
import { AnalyticEventAction, AnalyticEventName } from '@/services/analytics/analytics.types';

type Action = AnalyticEventAction.Login | AnalyticEventAction.LoginWithSSO;
type Value = 'missing fields';

interface Properties extends AnalyticsEventProperties {
  action: Action;
  value?: Value;
}

export const trackActionButtonAnalyticsEvent = (properties: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.ActionButton,
    properties
  });
};
