import {AnalyticsMeta, LoginResponse, Verify2FACodeResponse} from '@/components/login/login.types';
import { API_URLS } from '@/constants/api-urls.constants';
import { HttpService } from '@/services/http/http.service';

export const standardLogin = async (email: string, password: string): Promise<LoginResponse> => {
  return await HttpService.post<LoginResponse>({
    url: API_URLS.LOGIN,
    data: { email, password }
  });
};

export const standardLogout = async (): Promise<AnalyticsMeta> => {
  return await HttpService.get<AnalyticsMeta>({
    url: API_URLS.LOGOUT,
  });
}

export const verify2FACode = async (code: string, email: string, password: string) => {
  return await HttpService.post<Verify2FACodeResponse>({
    url: API_URLS.VERIFY_2FA_CODE,
    data: { code, email, password }
  });
};

export const resend2FACode = async (email: string, password: string) => {
  await HttpService.post({
    url: API_URLS.RESEND_2FA_CODE,
    data: { email, password }
  });
};

export const callWith2FACode = async (email: string, password: string) => {
  await HttpService.post({
    url: API_URLS.CALL_WITH_2FA_CODE,
    data: { email, password }
  });
};

export const checkUserRegisteredAfterSsoSucceed = async (accessToken: string) => {
  return await HttpService.post<LoginResponse>({
    url: API_URLS.LOGIN_WITH_SSO,
    data: { accessToken }
  });
};

export const checkSession = async () => {
  return await HttpService.get<Verify2FACodeResponse>({
    url: API_URLS.CHECK_SESSION
  });
};
