// TODO - do we need all this?
export enum AnalyticEventName {
  ActionButton = 'Action Button',
  Login = 'Login',
  Error = 'Error',
  InputUsage = 'Input Usage'
}

export enum AnalyticEventAction {
  LoginWithSSO = 'Log in with SSO',
  Login = 'Login',
  SSO = 'sso',
  Password = 'password',
}