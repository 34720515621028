import theme from '@/styles/theme/theme';

export const getTextButtonColor = (variant: 'primary' | 'secondary', isActive: boolean) => {
  if (isActive) {
    if (variant === 'primary') {
      return theme.palette.primary.dark;
    }

    return theme.palette.secondary.dark;
  }

  return variant === 'primary' ? theme.palette.primary.main : theme.palette.secondary.dark;
};
