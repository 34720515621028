import mixpanel from 'mixpanel-browser';

import { AnalyticsMeta } from '@/components/login/login.types';
import { ENV, MIXPANEL_TOKEN } from '@/constants/config.constants';
import { AnalyticEventName } from '@/services/analytics/analytics.types';
import { isProd, isTestAutomationInstitution } from '@/utils/EnvUtils';

// eslint-disable-next-line
export interface AnalyticsEventProperties {
  // TODO: implement this interface
}

export interface AnalyticsEvent<T extends AnalyticsEventProperties> {
  name: AnalyticEventName;
  properties: T;
}

const GLOBAL_INITIAL_PROPERTIES = {
  platform: 'portal',
  environment: ENV,
  page_name: 'Login'
};

class AnalyticsService {
  constructor() {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: !isProd,
      ignore_dnt: !isProd
    });

    this.setGlobalProperties(GLOBAL_INITIAL_PROPERTIES);
  }

  public analyticsSetup(analyticsMeta: AnalyticsMeta) {
    const { userId, institutionId, institutionName, releaseGroup } = analyticsMeta;
    const isTestInstitution = isTestAutomationInstitution(institutionId);

    if (isTestInstitution) {
      this.optOut();
    }

    this.identify(String(userId));

    this.setGlobalProperties({
      ...GLOBAL_INITIAL_PROPERTIES,
      release_group: releaseGroup
    });

    this.setProfileProperties({
      institution_id: institutionId,
      institution_name: institutionName,
      is_test_institution: isTestInstitution
    });
  }

  public trackEvent<T extends AnalyticsEventProperties>(event: AnalyticsEvent<T>) {
    const properties = event.properties || {};
    mixpanel.track(event.name, properties);
  }

  private identify(id: string): void {
    mixpanel.identify(id);
  }

  private setGlobalProperties(properties: { [p: string]: any }): void {
    mixpanel.register(properties);
  }

  private setProfileProperties(properties: { [p: string]: any }): void {
    mixpanel.people.set(properties);
  }

  private optOut() {
    mixpanel.opt_out_tracking();
  }

  reset() {
    // TODO: add when invaliding session cookie
    mixpanel.reset();
  }
}
const analyticsService = new AnalyticsService();
export default analyticsService;
