// TODO: update BASE_URL_V2
const BASE_URL_V2 = '/v2';

export const API_URLS = {
  LOGIN: `${BASE_URL_V2}/login`,
  LOGIN_WITH_SSO: `${BASE_URL_V2}/login-sso`,
  LOGOUT: `${BASE_URL_V2}/logout`,
  VERIFY_2FA_CODE: `${BASE_URL_V2}/verify-2fa-code`,
  RESEND_2FA_CODE: `${BASE_URL_V2}/resend-2fa-code`,
  CALL_WITH_2FA_CODE: `${BASE_URL_V2}/call-with-2fa-code`,
  CHECK_SESSION: `${BASE_URL_V2}/check-session`,
};
