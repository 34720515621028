import { Env } from '@/utils/env.types';

export const ENV = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development';
export const VERSION = process.env.NEXT_PUBLIC_VERSION;
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
export const MIXPANEL_TOKEN =
  process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || 'fake token required for mixpanel';
export const SKIP_REDIRECT_ON_DEVELOPMENT =
  process.env.NEXT_PUBLIC_SKIP_REDIRECT_ON_DEVELOPMENT === 'true';

export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
export const AUTH0_AUDIENCE = `https://${AUTH0_DOMAIN}/api/v2/`;

export const AUTOMATION_INSTITUTION_IDS: Record<Env, number[]> = {
  [Env.Staging]: [27, 16],
  [Env.PreProd]: [51, 52, 44],
  [Env.Demo]: [],
  [Env.Test]: [],
  [Env.Production]: [51, 52, 44],
  [Env.Development]: []
};

export const publicUrlsEnvVars =  {
  login: process.env.NEXT_PUBLIC_LOGIN,
  loginApi: process.env.NEXT_PUBLIC_LOGIN_API,
  portal: process.env.NEXT_PUBLIC_PORTAL,
  portalCanary: process.env.NEXT_PUBLIC_PORTAL_CANARY,
  api: process.env.NEXT_PUBLIC_API,
  apiCanary: process.env.NEXT_PUBLIC_API_CANARY
}
