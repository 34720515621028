import { Env } from './env.types';

import {
  AUTOMATION_INSTITUTION_IDS,
  ENV,
  SKIP_REDIRECT_ON_DEVELOPMENT
} from '@/constants/config.constants';

interface AutomationWindow extends Window {
  QA_WOLF?: boolean;
}

export const isDev = ENV === Env.Development;
export const isProd = ENV === Env.Production;
export const isStaging = ENV === Env.Staging;
export const isPreProd = ENV === Env.PreProd;
export const isDemo = ENV === Env.Demo;
export const skipRedirectOnDev = isDev && SKIP_REDIRECT_ON_DEVELOPMENT;

export const isTestAutomationInstitution = (institutionId: number) => {
  const testInstitutionIds = AUTOMATION_INSTITUTION_IDS[ENV as Env];
  return testInstitutionIds.includes(institutionId);
};

export const getIsAutomationClient = (): boolean | undefined => {
  // should run only in the browser
  const automationWindow: AutomationWindow = window;
  return automationWindow.QA_WOLF === true;
};
